import React from 'react'
import { trackNewsletterSignup } from 'analytics'
import { EmailInput } from '../inputs'
import { SectionTheme, SectionSize } from 'types/misc'
import { SanitySection } from './SanitySection'
import { sizes } from 'styles'
import styled from 'styled-components'
import { subscribeToKlaviyoEmailList } from '../../lib/klaviyo'

type Props = {
  title?: string
  description?: string
  id?: string
  theme?: SectionTheme | ''
  size?: SectionSize | ''
}

function EmailCaptureSection({
  id = '',
  title,
  description,
  size = '',
  theme = 'gray'
}: Props) {
  const handleEmailSubmit = async (email: string) => {
    await subscribeToKlaviyoEmailList(email);
    await trackNewsletterSignup(email);
  };

  return (
    <Container className='email-capture-section'>
      <SanitySection
        size={size}
        width={`${sizes.medium}px`}
        id={id}
        theme={theme}
      >
        <Header>
          <h2>{title || 'Stay connected with Rachio!'}</h2>
          <p>{description || 'Get the latest tips, deals, and news straight to your inbox. By clicking Subscribe you are agreeing to the Rachio Terms of Use.'}</p>
        </Header>
        <EmailInput
          id="newsletter-submit"
          onSubmit={handleEmailSubmit}
        />
      </SanitySection>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: var(--white);

  form {
    margin: 0 auto;
    max-width: 768px;
  }
`

const Header = styled.div`
  h2 {
    margin-top: 0;
  }
  text-align: center;
  p {
    max-width: 600px;
    margin: 0 auto 3rem;
  }
`

export default EmailCaptureSection
